@import "../assets/styles";

.app-container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;

  width: 960px;
  max-width: 100%;

  @include min-sm {
    max-width: 95%; } }
