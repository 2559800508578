html, body {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

* {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

button {
  border: none;
  outline: none;
}

a {
  color: #FFFFFF;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
}

a:focus {
  color: #FFFFFF;
  text-decoration: underline;
}

.show-modal {
  overflow: hidden;
}

/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
