@import "../assets/styles";

.app-input {
  height: 46px;
  width: 100%;
  border: 1px solid border-color('input');
  border-radius: 8px;
  transition: transition('input');
  outline: none;
  padding: 15px 20px;

  &::placeholder {
    color: text-color('secondary1');
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px; }

  &:focus {
    border-color: border-color('input-focused'); }

  &.is-invalid {
    border-color: red; } }
