@import "../assets/styles";

.app-modal {
  &-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#42434D, 0.39);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    //transition: top 0.75s

    &.is-open {
      top: 0; } } }
