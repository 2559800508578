@import "../assets/styles";

.l {
  &-pre-title {
    color: text-color('secondary2');
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: normal; }

  &-title {
    color: text-color('primary');
    font-size: 35px;
    letter-spacing: 0;
    line-height: 52px;
    font-weight: normal;

    @include min-lg {
      font-size: 40px; } }

  &-text {
    color: text-color('secondary1');
    font-size: 18px;
    letter-spacing: 0;
    line-height: 35px;
    font-weight: normal; } }
// *** Main section
.l-main {
  &__content {
    display: flex;
    flex-direction: column;

    @include min-xs {
      flex-direction: row-reverse;
      justify-content: flex-end; } }

  &__video {
    width: 232px;

    &--second {
      margin-right: 6px;
      margin-left: 13px;
      margin-top: 24px; }

    video {
      width: 100%; } }

  &__logos {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;

    @include min-lg {
      margin-top: 60px; } }

  &__logo {
    width: 236px; }

  &__description {
    padding: 0 13px;
    margin-top: 8px;

    @include min-xs {
      width: 50%;
      max-width: 470px;
      margin-right: 50px;
      margin-top: 18px; }

    @include min-lg {
      width: unset;
      max-width: 489px;
      min-width: 489px;
      padding: 0 10px;
      margin-right: 49px; } }

  &__title {
    color: text-color('primary');
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 45px;
    padding: 0 5px;

    @include min-xs {
      margin-top: 20px; }

    @include min-lg {
      color: text-color('primary');
      font-size: 56px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 60px;
      padding: 0;
      margin-top: 59px; } }

  &__points {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    @include min-lg {
      margin-top: 41px; } }

  &__point {
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-basis: 45.5%;

    @include min-lg {
      flex-basis: 43.5%; }

    &:nth-child(n + 3) {
      margin-top: 7px; }

    &-icon {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;

      &-wrapper {
        height: 11px;
        width: 15px; } }

    &-text {
      color: text-color('secondary3');
      font-size: 16px;
      letter-spacing: 0;
      line-height: 36px;
      margin-left: 8px;

      @include min-lg {
        font-size: 19px; } } }


  &-form {
    margin-top: 20px;

    @include min-xs {
      display: flex; }

    @include min-lg {
      margin-top: 26px;
      max-width: 455px; }

    &__button.app-button {
      margin-top: 10px;

      @include min-xs {
        margin-top: 0;
        width: 169px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0; } }

    &__input.app-input {
      @include min-xs {
        flex: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; } } } }

.l-about {
  margin: 30px 0 0;

  @include min-lg {
    margin: 57px 0 0;
    padding-bottom: 31px;
    background-image: url('./images/about-path.png');
    background-position: 100% 87%;
    background-repeat: no-repeat;
    padding-right: 27px;
    background-size: contain; }

  &__pre-title {
    margin: 0; }

  &__title {
    margin: 10px 0 0;

    @include min-lg {
      margin: 19px 0 0;
      padding-right: 40px; } }

  &__text {
    margin: 20px 0 0;
    padding-right: 6px;

    @include min-lg {
      margin: 4px 0 0;
      padding-right: 0; } }

  &-content {
    padding: 0 15px;

    @include min-lg {
      width: 752px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 35px 0 rgba(0,0,0,0.08);
      padding: 22px 33px 51px 52px;
      position: relative;
      margin: 0 auto;
      left: 14px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 7px;
        border-radius: 4px;
        background-color: #466AE0; } } } }

// *** Services section
.l-services {
  &__pre-title {
    margin: 34px 0 0;

    @include min-xs {
      margin: 41px 0 0; } }

  &__title {
    margin: 12px 0 18px;
    padding-right: 12px;

    @include min-xs {
      margin: 21px 0 3px; } }

  &__list {
    margin-top: 59px;

    @include min-xs {
      margin-top: 174px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start; } }

  &__text {
    color: text-color('secondary1');
    font-size: 18px;
    letter-spacing: 0;
    line-height: 35px; }

  &__content {
    padding: 0 13px;

    @include min-xs {
      padding: 0 10px; } }

  &-divider {
    margin: 35px 15px 0;
    border-top: 1px solid rgba(#466AE0, 0.23);

    @include min-xs {
      display: none; } }

  &-group {
    @include min-xs {
      margin: 0 auto;
      display: flex;
      align-items: flex-start; }

    &:not(:first-child) {
      margin-top: 42px;

      @include min-xs {
        margin-top: 57px; } } } }


.l-service {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include min-xs {
    position: relative;
    width: 360px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 35px 0 rgba(0,0,0,0.06); }

  &:not(:first-child) {
    margin-top: 42px;

    @include min-xs {
      margin-left: 20px;
      margin-top: 77px; } }

  @include min-lg {
    width: 460px; }

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;

    &-wrapper {
      height: 185px;
      width: 295px;
      box-shadow: 0 2px 27px 0 rgba(0,0,0,0.06);
      position: relative;
      left: 10px;

      @include min-xs {
        top: -87px;
        box-shadow: 0 2px 35px 0 rgba(0,0,0,0.06);
        left: 4px; }

      @include min-lg {
        height: 243px;
        width: 388px; }

      &:after {
        content: '';
        position: absolute;
        top: 35px;
        left: -12px;
        height: 170px;
        width: 272px;
        background-color: #EEEDFF;
        z-index: 0;

        @include min-lg {
          height: 100%;
          width: 100%;
          top: 25px;
          left: -33px; } } } }

  &__text {
    z-index: 1;
    position: relative;
    color: text-color('primary');
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
    margin: 55px 5px 0;

    @include min-xs {
      margin: -26px 33px 75px 35px; } } }

// **** Guesty section
.l-guesty {
  &__content {
    padding: 0 17px;

    @include min-xs {
      padding: 0 10px; } }

  &__logo {
    &-wrapper {
      margin-top: 50px;

      @include min-xs {
        margin-top: -2px;
        margin-left: 3px; } } }

  &__title {
    margin: 17px 0 0;

    @include min-xs {
      margin: 15px 0 0; }

    @include min-lg {
      padding-right: 150px; } } }

// *** Features title
.l-features {
  @include min-xs {
    margin-top: 64px; }

  &__content {
    margin: 0 15px;

    @include min-xs {
      margin: 0 10px; } }

  &__title {
    margin: 10px 0 0;

    @include min-xs {
      margin: 21px 0 0; } }

  &__list {
    max-width: 345px;
    margin: 0 auto;

    @include min-xs {
      max-width: 710px;
      margin: 65px auto 0; }

    @include min(xm) {
      max-width: 1192px; }

    &-arrows {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px; }

    &-arrow {
      width: 36px;
      height: 36px;
      margin: 0 10px;

      @include min-xs {
        margin: 0 5px; }

      &.swiper-button-disabled {
        opacity: 0.24; }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain; } } } }

.l-feature {
  width: 345px;
  max-width: 345px;

  @include min-xs {
    max-width: 384px;
    width: 384px; }

  &__title {
    color: text-color('primary-light');
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px; }

  &__note {
    padding: 17px 10px;
    min-height: 90px;
    border-radius: 0 0 10px 10px;
    background-color: theme('background-light');
    box-shadow: 0 2px 16px 0 rgba(188,188,188,0.5);
    display: flex;
    align-items: center;

    @include min-xl {
      padding: 17px 25px 17px 28px; } }

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &-wrapper {
      height: 189px;
      border-radius: 12px; } }

  &__icon {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &-wrapper {
      height: 35px;
      width: 35px;
      object-fit: contain; } }

  &__text {
    color: text-color('primary-light');
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
    flex: 1;
    margin-left: 16px; } }

// *** Starting section
.l-starting {
  width: 90%;
  border-radius: 15px;
  background-color: theme('background-dark');
  box-shadow: 0 2px 35px 0 rgba(0,0,0,0.03);
  padding: 50px 21px 54px;
  margin: 50px auto 0;

  @include min-xs {
    margin: 40px auto 0;
    max-width: 1152px; }

  @include min-xl {
    padding: 109px 21px 59px; }

  &__title {
    color: text-color('primary-light');
    font-size: 40px;
    letter-spacing: 0;
    line-height: 52px;
    text-align: center; }

  &__text {
    color: text-color('primary-light');
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    padding: 0 22px;
    margin-top: 15px;

    @include min-xl {
      margin-top: 0; } }

  &__arrow {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;

    &-wrapper {
      height: 24px;
      width: 24px;
      margin: 25px auto 0;

      @include min-xl {
        margin: 12px auto 0; } } }

  &__button {
    margin: 20px auto 0;
    min-width: 234px;
    display: block;

    &.app-button {
      &:hover {
        background: #304ca7; } } }

  &__demo-link {
    margin-top: 24px;
    text-align: center;
    display: block; } }

// *** Bottom spacer
.l-bottom-spacer {
  height: 9px;

  @include min-lg {
    height: 47px; } }
