@import "../assets/styles";

.blog-pre-title {
  color: text-color('secondary2');
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px; }

.blog-main {
  background-image: url('images/main.png');
  background-size: cover;
  position: relative;

  &__gradient {
    background: linear-gradient(0deg, #466AE0 0%, rgba(70,106,224,0.34) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

  &__content {
    padding: 86px 0 87px;
    position: relative; }

  &__pre-title {
    color: text-color('primary-light');
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px; }

  &__title {
    color: text-color('primary-light');
    font-size: 45px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 60px;
    margin: 14px 0 0;
    max-width: 526px; }

  &__continue-btn {
    &.app-button {
      background-color: text-color('primary-light');
      color: #466AE0;
      margin-top: 33px; } }

 }  // *** Trending section
.blog-trending {
  margin-top: 45px;

  &-pre-title {
    margin: 0 0 36px; }

  &-list {
    max-width: 790px;
    width: 100%;
    margin: 0 auto;
    position: relative; }

  &-item {
    width: 250px;

    &__logo {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &-wrapper {
        height: 190px;
        width: 250px; } }

    &__text {
      color: text-color('primary');
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
      margin-top: 20px; } }

  &-arrow {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    &--left {
      left: -55px;
      bottom: 170px;

      img {
        transform: rotate(180deg); } }

    &--right {
      bottom: 170px;
      right: -55px; } } }

// *** Blog recent item
.blog-recent {
  margin-bottom: 110px;

  &-articles {
    margin: 110px 80px 0; }

  &-pre-title {
    margin: 0 10px 35px; }

  &-list {}

  &-item {
    display: flex;
    text-align: left;

    &:not(:first-child) {
      margin-top: 25px; }

    &__logo {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &-wrapper {
        height: 235px;
        width: 309px; } }


    &__content {
      &-wrapper {
        flex: 1;
        padding: 28px 22px 22px 30px; } }

    &__title {
      color: text-color('primary');
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px; }

    &__text {
      margin-top: 10px;
      color: text-color('secondary1');
      font-size: 18px;
      letter-spacing: 0;
      line-height: 25px; }

    &__divider {
      border-top: 1px solid rgba(#20212F, 0.1);
      margin-top: 15px; }

    &__date {
      color: text-color('secondary2');
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      margin-top: 17px; } } }
