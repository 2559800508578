@import "variables";

@function theme($key) {
  @return map-get($theme, $key); }

@function transition($key: 'default') {
  @return map-get(map-get($theme, 'transition'), $key); }

@function border-color($key) {
  @return map-get(map-get($theme, 'border'), $key); }

@function text-color($key) {
  @return map-get(map-get($theme, 'text'), $key); }

$breakpoints: (sm: 576,xs: 768,lg: 1140, xm: 1250, xl: 1440);

@mixin min($key) {
  @media screen and (min-width: #{map-get($breakpoints, $key)}px) {
    @content; } }

@mixin min-sm() {
  @include min(sm) {
    @content; } }

@mixin min-xs() {
  @include min(xs) {
    @content; } }

@mixin min-lg() {
  @include min(lg) {
    @content; } }

@mixin min-xl() {
  @include min(xl) {
    @content; } }
