@import "../assets/styles";

.app-header {
  &-content {
    padding: 0 17px 6px 19px;
    display: flex;
    align-items: center;
    height: 66px;

    @include min-xs {
      height: 92px;
      padding: 0 0 6px 10px; } }

  &-menu {
    width: 24px;
    height: 24px;
    margin-left: 20px;

    @include min-xs {
      display: none; } }

  &-button {
    &.app-button {
      font-size: 13px;
      height: 24px;
      border-radius: 4px;
      padding: 4px 14px;
      width: unset;
      background: theme('background-dark');

      @include min-lg {
        height: 46px;
        width: 189px;
        border-radius: 8px;
        position: absolute;
        top: -23px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        right: -65px; }

      @include min-xl {
        right: -127px; } }

    &-container {
      margin-left: auto;
      position: relative;

      @include min-xs {
        display: none; }

      @include min-lg {
        display: block;
        margin-left: 165px; }

      @include min-xl {
        margin-left: 92px; } } }

  &-links {
    display: none;

    @include min-xs {
      display: flex;
      align-items: center;
      margin-left: auto; } }

  &-link {
    display: block;
    color: text-color('primary');
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;

    &:not(:first-child) {
      @include min-xs {
        margin-left: 20px; }

      @include min-lg {
        margin-left: 35px; } }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: text-color('primary'); }

    &.is-active {
      color: #466AE0; }

    &--trial {
      @include min-lg {
        display: none; } } } }
