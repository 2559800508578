@import "../assets/styles";

.app-footer {
  background: theme('background-dark');

  &__content {
    padding: 29px 0 76px;
    margin: 0 15px;

    @include min-xs {
      padding: 96px 0 68px;
      margin: 0 10px; } }

  &__logo {
    &-wrapper {} }

  &-section {
    flex-basis: 50%;

    @include min-xs {
      flex-basis: 25.5%; }

    &--social {
      margin-top: 30px;

      @include min-xs {
        order: 0;
        margin-top: 15px; }

      .app-footer-section {
        &__title {
          @include min-xs {
            display: none; } } } }

    &--company {
      @include min-xs {
        order: 1;
        flex-basis: 20.5%;
        position: relative;
        top: -38px; } }

    &--contact {
      @include min-xs {
        order: 2;
        position: relative;
        top: -38px; } }
    &-list {
      display: flex;
      flex-wrap: wrap; }

    &__title {
      color: text-color('primary-light');
      font-size: 17px;
      letter-spacing: 2px;
      line-height: 22px;

      @include min-xs {
        text-transform: uppercase;
        font-weight: 500; } }

    &__content {
      margin-top: 38px;

      @include min-xs {
        margin-top: 41px; } }

    &__link {
      display: block;
      text-align: left;

      @include min-xs {
        letter-spacing: 2.35px;
        font-size: 14px; }

      &:not(:first-child) {
        margin-top: 14px;

        @include min-xs {
          margin-top: 20px; } }

      &--email {
        @include min-xs {
          font-size: 20px; } } } }

  &-socials {
    display: flex;
    margin-top: 20px;

    @include min-xs {
      margin-top: 4px; }

    &__icon {
      object-fit: contain;

      &-wrapper {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px; } } }

  &__copyrights {
    display: none;
    color: #A1A1A1;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    padding-bottom: 56px;

    @include min-xs {
      display: block; } } }

