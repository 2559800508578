.trial-modal {
  height: 313px;
  width: 570px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 35px 0 rgba(0,0,0,0.08);
  display: flex;

  &-sidebar {
    width: 209px;
    height: 100%;
    padding: 105px 10px 0 41px;
    background-color: #16133E;

    &__line1 {
      color: #466AE0;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 16px; }

    &__line2 {
      color: #FFFFFF;
      font-size: 30px;
      letter-spacing: 0;
      line-height: 52px; }

    &__line3 {
      color: #FFFFFF;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 16px; } }


  &-content {
    background: #fff;
    flex: 1;
    padding: 45px 54px 0 38px; }

  &-form {
    &__input {}
    &__back {
      &.app-button {
        height: 48px;
        width: 106px;
        border: 2px solid #E5EAF4;
        border-radius: 8px;
        background: #fff;
        color: #E5EAF4;
        margin-right: 10px;

        &:hover {
          color: #16133E;
          border: 2px solid #16133E; } } }

    &__group {
      &:not(:first-child) {
        margin-top: 10px; }


      .form-error {
        display: none;
        color: red;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 16px;
        margin: 4px 0; }

      &.is-invalid {
        .form-error {
          display: block; } } }

    &__submit {
      &.app-button {
        max-width: 153px;
        flex: 1; } } }

  &-actions {
    display: flex;
    margin-top: 20px; }

  &-completed {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    flex: 1;
    height: 100%;

    &__logo {
      width: 84px; }

    &__title {
      color: #16133E;
      font-size: 30px;
      letter-spacing: 0;
      line-height: 52px; }

    &__text {
      color: #16133E;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 28px; } } }
