@import "../assets/styles";

.app-button {
  color: text-color('primary-light');
  background-color: theme('background-light');
  box-shadow: theme('shadow');
  height: 46px;
  border-radius: 8px;
  font-weight: 500;
  padding: 4px 35px 5px;
  cursor: pointer;
  font-size: 16px;
  transition: transition();
  border: 1px solid rgba(22, 19, 62, 0.48);

  &:hover {
    background-color: rgba(theme('background-dark'), 0.8); }

  &:focus,
  &:active {
    border: 1px solid rgba(22, 19, 62, 0.48); } }
